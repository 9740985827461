import Head from 'next/head';
import Link from 'next/link';
import { CtaButton } from '@lambda/ui/src/Cta';
import i18n from '@/settings/i18n';
import settings from '../settings/configs';
import * as Sentry from '@/lib/inject-sentry';

const t = i18n.init();

export default function ErrorPage(props: any) {
  console.log(props);

  return (
    <>
      <Head>
        <title>{t`Error ${{
          errorCode: props.statusCode,
        }} - Reebelo ${{
          country: settings.country,
        }}`}</title>
      </Head>
      <div className="flex h-screen items-center justify-center bg-white">
        <div className="flex flex-wrap">
          <div className="flex min-w-full justify-center">
            <h1 className="border-r py-2 pr-2 text-xl xxs:pr-4 xxs:text-2xl">
              {props.statusCode}
            </h1>
            <h2 className="my-auto pl-2 xxs:pl-4">{t`Oops, something went wrong`}</h2>
          </div>
          <div className="mt-4 min-w-full text-center xxs:mt-5 xs:mt-6">
            <Link href="/" passHref>
              <CtaButton className="min-w-[60%] xxs:min-w-0">{t`Continue Shopping`}</CtaButton>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

ErrorPage.getInitialProps = async (props: any) => {
  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  const { res, err, error, req } = props;
  const statusCode = res?.statusCode || err?.statusCode;
  const results = { error: error || err, statusCode };

  // Capture all http error (only work with internal link)
  // Any API/script error should not go here
  // Will ignore if is a blacklisted rls and user agent is aws cloudfront
  const BLACKLISTED_URLS = [
    /^\/_next\/data\//,
    /\/wlwmanifest\.xml$/,
    /xmlrpc\.php/,
  ];
  const isBlacklisted =
    req?.url && BLACKLISTED_URLS.some((b) => b.test(req.url));
  const userAgent =
    req?.headers?.['user-agent'] || req?.headers?.['User-Agent'];

  if (
    res?.statusCode != null &&
    res?.statusCode !== '' &&
    !isBlacklisted &&
    userAgent !== 'Amazon CloudFront'
  ) {
    Sentry.withScope((scope) => {
      scope.setLevel(Sentry.Severity.Error);
      scope.setTag('url', req?.url);
      scope.setTag('http_method', req?.method);
      scope.setTag('http_error_code', res?.statusCode);
      scope.setTag('http_status_msg', req?.statusMessage);
      scope.setTag('store', settings.store);
      scope.setTag('referer', req?.headers?.referer);
      scope.setTag('utm_source', req?.headers?.utm_source);
      scope.setTag('utm_campaign', req?.headers?.utm_campaign);
      scope.setTag('user_agent', userAgent);
      Sentry.captureMessage(`HTTP Error - ${res?.statusCode} -  ${req?.url}`);
    });
  }

  // Any API/script error go here
  if (err) {
    Sentry.captureException(err);
    await Sentry.flush(2000);

    return results;
  }

  return results;
};
